import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { sendData, deselectOrder, deselectOrSearch } from '../actions/RmaActions'
import ProductListItem from './ProductListItem'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  group_treshold: state.config.group_treshold,
  order: state.rma.order,
  lang: state.auth.lang
})

const decisionValues = {
  '0': 'valid',
  '1': 'invalid',
  '2': 'no_refund'
}

const fillDecision = dict => {
  let res = {}
  for (const key in dict) {
    res[key] = decisionValues[dict[key]]
  }
  return res
}

const mapDispatchToProps = dispatch => ({
  sendDataAction: (data) => dispatch(sendData(data)),
  deselectOrderAction: () => dispatch(deselectOrder()),
  deselectOrSearchAction: (kod, id) => dispatch(deselectOrSearch(kod, id)),
})

class OrderComponent extends React.Component {
  constructor(props) {
    super(props)
    const { order } = props
    this.lines = []
    this.state = {
      valid: false,
      internal_note: order.internal_note || ''
    }
  }

  getProductListItem = (product, i) => (
    <ProductListItem key={product.id}
                     childIndex={i}
                     product={product}
                     parent={this}/>
  )

  deselectOrder = (event) => {
    event.preventDefault();
    const { deselectOrSearchAction, order } = this.props;
    const kod = order.code;
    const id = null;
    deselectOrSearchAction(kod, id);
  }

  noteChange = (event) => {
    this.setState({internal_note: event.target.value})
  }

  collectData = () => {
    const {lines} = this
    const {order} = this.props
    const {internal_note} = this.state
    let result = {
      internal_note: internal_note,
      lines: []
    }
    if(order.id){
      result.id = order.id
    }
    if(order.order_id){
      result.order_id = order.order_id
    }
    result.lines = lines.map(line => {
      const { decision, action } = line.state
      const { product } = line.props
      const decision_filled = fillDecision(decision)
      let items = []
      for (const key in decision_filled) {
        items[key] = {
          decision: decision_filled[key],
          action: action[key]
        }
      }
      return {
        id: product.id,
        sale_order_line_id: product.sale_order_line_id,
        items: items
      }
    })
    return result
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { sendDataAction } = this.props
    sendDataAction(this.collectData())
  }

  toggleConfirmButton = () => {
    let state = {}
    const {valid} = this.state
    const {lines} = this
    const validArr = lines.map(line => {
      let {decision, action} = line.state
      decision = Object.values(decision)
      action = Object.values(action)
      const {product} = line.props
      const qty = product.quantity % 1 === 0 ? product.quantity : 1
      if (decision.length < qty ||
          decision.some(d => (typeof d == 'undefined' || d === false))){
        return false
      }
      if (action.length < qty ||
          action.some((a, index) => {
            const availabe_actions = {
              '0': product.approve_options,
              '1': product.decline_options,
              '2': []
            }[decision[index]]
            if(availabe_actions.length > 0){
              return (typeof a == 'undefined' || a === false)
            }
            return false
          })){
        return false
      }
      return true
    })
    if(validArr.every(v => v)){
      state.valid = true
    } else {
      state.valid = false
    }
    if(state.valid !== valid){
      this.setState(state)
    }
  }

  render() {
    const { authenticated } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    const { order } = this.props
    const { valid, internal_note } = this.state
    return (
      <div className="rma">
        <div>
          <div className="rma_header">
            <button className="pull-left secondary" onClick={this.deselectOrder}>{intl.get('Anuluj')}</button>
            <button onClick={this.handleSubmit} className="pull-right" id="zatwierdz" disabled={!valid} >{intl.get('Dalej')}</button>
          </div>
          {order &&
            <div className="rma_content row">
              <div className="col-lg-5">
                <div className="rma_order ramka">
                  <h4>
                    {intl.get('Zamówienie')} {order.order_name}
                  </h4>
                  <div className="order_data">
                    <div className="date_order f_group">
                      <label>{intl.get('Data zamówienia')}:</label>
                      <div>{order.date_order.substring(0, order.date_order.lastIndexOf(':'))}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Numer faktury')}:</label>
                      <div>{order.invoice_nr}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Data zafakturowania')}:</label>
                      <div>{order.invoice_date}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Sklep')}:</label>
                      <div>{order.shop}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Kwota zamówienia')}:</label>
                      <div>{order.amount_total}</div>
                    </div>
                    <div className="amount_total f_group">
                      <label>{intl.get('Forma płatności')}:</label>
                      <div>{order.payment_term}</div>
                    </div>
                    <div className="delivery f_group">
                      <label>{intl.get('Adres dostawy')}:</label>
                      <div>
                        <div>{order.delivery_name}</div>
                        <div>{order.delivery_street}</div>
                        <div>{order.delivery_zip} {order.delivery_city}</div>
                      </div>
                    </div>
                    <div className="notes f_group">
                      <label>{intl.get('Uwagi klienta')}:</label>
                      <div>{order.client_note}</div>
                    </div>
                    <div className="notes f_group">
                      <label>{intl.get('Uwagi własne')}:</label>
                      <div>
                        <textarea
                          value={internal_note}
                          onChange={this.noteChange}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rma_products col-lg-7">
                {order.lines.map((product, i) => this.getProductListItem(product, i))}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
const Order = connect(mapStateToProps, mapDispatchToProps)(OrderComponent)
export default withRouter(Order)
